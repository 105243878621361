import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "C:/Users/tsuto/webapp/pcrecommend/src/components/layout.js";
import { Link, graphql } from "gatsby";
import HikakuTable from "components/hikakuTableLoadable";
import AccentBox from "components/accentBox";
import Image from "components/image";
import Socials from "components/socials";
import BasicTable from "components/basicTable";
import BenchMark from 'components/cpu/benchmark';
import CpuMatsubi from 'components/cpu/cpu_matsubi';
import HowtoFilter from 'components/cpu/howtofilter';
import TableDescription from 'components/cpu/table_description';
import PassMark from 'components/cpu/passmark';
export const query = graphql`
  {
    allMdx(filter: {slug: {eq: "cpu/cospa"}}) {
      nodes {
        headings {
          depth
          value
        }
      }
    },
    cover: file(relativePath: { eq: "cover/cpu/cpucospa.png"}) { ...eyecatchImg },
    allCospaJson {
      nodes {
        name
        pm
        pms
        year
        clock
        core
        thread
        tdp
        price
        cospa
        rank
      }
    },
  }
`;
export const _frontmatter = {};
const layoutProps = {
  query,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`【コスパ最強】おすすめCPU50選`}</h1>
    <time itemProp="datePublished" dateTime="Sat Apr 05 2025 03:50:22 GMT+0900 (日本標準時)">更新日:2025年4月5日</time>
    <Socials {...props} mdxType="Socials" />
    <Image {...props} name="cover" alt="コスパ最強CPU" mdxType="Image" />
    <p>{`自作パソコンにしても購入するにしてもコストパフォーマンスの高いCPUを知っていれば、それだけ自分の求める性能に見合ったパソコンを低価格で手に入れる事ができる。`}</p>
    <p>{`今回はCPUの総合的な能力を示すPASSMARKを価格で割った値をコスパと定義して、コスパの高いCPUを上位から紹介していく。`}</p>
    <p>{`また、より多くのCPUを探したい場合は`}<a href="/cpu/">{`CPU性能比較表`}</a>{`を参考にして欲しい。このページではコスパパフォーマンスが高いCPUを厳選して紹介する。ランキング表の見方やベンチマークの意味に関しては下記を参照して欲しい。`}</p>
    <BenchMark mdxType="BenchMark" />
    <CpuMatsubi mdxType="CpuMatsubi" />
    <TableDescription mdxType="TableDescription" />
    <HowtoFilter mdxType="HowtoFilter" />
    <h2 {...{
      "id": "あなたにとって高いコスパのCPUは何か？",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%E3%81%82%E3%81%AA%E3%81%9F%E3%81%AB%E3%81%A8%E3%81%A3%E3%81%A6%E9%AB%98%E3%81%84%E3%82%B3%E3%82%B9%E3%83%91%E3%81%AECPU%E3%81%AF%E4%BD%95%E3%81%8B%EF%BC%9F",
        "aria-label": "あなたにとって高いコスパのCPUは何か？ permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`あなたにとって高いコスパのCPUは何か？`}</h2>
    <p>{`CPUには大きくシングルコア性能とマルチコア性能という２つの性能指標がある。このうち`}<strong parentName="p">{`シングルコア性能はWebサイトを閲覧する時の快適さやアプリ起動、ゲーミング性能に大きく影響`}</strong>{`する。一方で`}<strong parentName="p">{`マルチコア性能は動画のエンコードやレンダリングといったクリエイティブなタスクや複数の重い処理を平行して行う時に大きく影響`}</strong>{`する。簡単に表にまとめる。`}</p>
    <AccentBox title="シングルコアが有利なタスク" mdxType="AccentBox">
  <li><span className="bold">Webサイトの閲覧</span>・・・Webサイトの言語(JavaScript)がマルチスレッド対応していない関係もあり、Webサイトの表示までの速度はシングルスレッド優位である。快適にWebサイトで買い物や動画視聴を楽しむ場合はコア数を気にしなくても良い。</li>
  <li><span className="bold">アプリケーションの起動</span>・・・大抵のアプリはシングルコア性能が高い方が立ち上がりが速い。複数のスレッドを回してアプリの立ち上げを速くするようなスキームは聞いたことがない。</li>
  <li><span className="bold">ゲーミング</span>・・・マルチコアの性能を十分に活用できていないゲームが多いため、結果としてシングルコア性能が高いCPUの方がベンチマークスコアが高く、動作が安定する。</li>
    </AccentBox>
    <p>{`アプリがサクサク動くのを重視したいならばシングルコア性能が重要となる。次にマルチコアが有利なタスクについて`}</p>
    <AccentBox title="マルチコアが有利なタスク" mdxType="AccentBox">
  <li><span className="bold">動画のエンコード</span>・・・動画編集ソフトで最後に動画ファイルとして書き出す処理、FullHDで書き出すには遅いノート用のCPUだと15分程度の動画が30分以上掛かったりするが、マルチスレッドに強いデスクトップCPUだと５分程度にまで短縮ができる。</li>
  <li><span className="bold">3Dのレンダリング</span>・・・リアルに描画された映像を作る時に、光や物理演算が必要となるが、その性能。マルチスレッドで高速化できる。</li>
  <li><span className="bold">圧縮ファイルの解凍/圧縮</span>・・・大抵のアプリはシングルコア性能が高い方が立ち上がりが速い。複数のスレッドを回してアプリの立ち上げを速くするようなスキームは聞いたことがない。</li>
  <li><span className="bold">ゲームをしながらYoutube実況</span>・・・動画配信のリアルタイムエンコードとゲームという複数の重いタスクは複数のCPUコアに仕事を任せられるマルチスレッドが得意とするところ</li>
  <li><span className="bold">ウイルス対策ソフトを回しながらWebブラウジング</span>・・・ある程度負荷のかかる作業をバックグラウンドで行う時でもマルチコア性能に強ければ安定して動作できる。</li>
    </AccentBox>
    <p>{`よってどういったパソコンの使い方をするかによって、おすすすめのCPUは変わってくるのである。ちなみスレッドについて簡単に説明すると、スレッドは並列演算が可能な数で、１つのコア当たり２スレッド持つこともある。`}</p>
    <p>{`ランキングとしてはPASSMARKのスコア順に算出するが、PASSMARKはコア数・スレッド数が多いCPUが高い得点を出しやすい傾向にある。よってPassMarkのシングルコア結果も同時に掲載する。区別するために`}<em parentName="p">{`総合スコアのPASSMARKはPASSMARK(M)`}</em>{`、`}<em parentName="p">{`シングルコアはPASSMARK(S)`}</em>{`として表記している。`}</p>
    <p>{`使い方によってはオーバースペックとなって割に合わない場合もあるため注意しよう。PASSMARKの目安を上げるので参考としてほしい。`}</p>
    <PassMark mdxType="PassMark" />
    <h2 {...{
      "id": "事務やゲーム用途はIntel、クリエイティブや同時並行作業はAMD",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%E4%BA%8B%E5%8B%99%E3%82%84%E3%82%B2%E3%83%BC%E3%83%A0%E7%94%A8%E9%80%94%E3%81%AFIntel%E3%80%81%E3%82%AF%E3%83%AA%E3%82%A8%E3%82%A4%E3%83%86%E3%82%A3%E3%83%96%E3%82%84%E5%90%8C%E6%99%82%E4%B8%A6%E8%A1%8C%E4%BD%9C%E6%A5%AD%E3%81%AFAMD",
        "aria-label": "事務やゲーム用途はIntel、クリエイティブや同時並行作業はAMD permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`事務やゲーム用途はIntel、クリエイティブや同時並行作業はAMD`}</h2>
    <p>{`用途別のおすすめを問われると`}<strong parentName="p">{`クリエイティブや同時並行作業が多い人はマルチスレッド重視で、コア数が多いAMDのRyzenがおすすめでき、事務用途やゲーミングではシングルコア性能が相対的に高いIntelのCore iシリーズがおすすめ`}</strong>{`できる。`}</p>
    <p>{`また、MDのデスクトップ用のCPUは`}<strong parentName="p">{`Gシリーズを除いてグラフィック機能が無く、グラボも同時に購入する必要がある`}</strong>{`。`}</p>
    <p>{`グラフィック機能がないとマザーボードからの映像出力が無くなり、４画面などの複数画面出力が難しくなるところが個人的には地味に痛い。Intelも12900Fなど`}<strong parentName="p">{`末尾にFが付くシリーズは別途モニタへの映像出力でグラボが必要`}</strong>{`なため注意しよう。`}</p>
    <h2 {...{
      "id": "コスパ最強のCPU50選",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%E3%82%B3%E3%82%B9%E3%83%91%E6%9C%80%E5%BC%B7%E3%81%AECPU50%E9%81%B8",
        "aria-label": "コスパ最強のCPU50選 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`コスパ最強のCPU50選`}</h2>
    <p>{`それではCPUをコスパの上位からランキングで紹介する。基本的にデスクトップパソコンがノートパソコンに比べてコスパが高いので殆どがデスクトップ用CPUとなっている。`}</p>
    <HikakuTable {...props} checkboxes={['corei', 'ryzen', 'celeron', 'notedesk']} cpuData={props.data.allCospaJson.nodes} sort={{
      fieldName: 'cospa'
    }} mdxType="HikakuTable" />


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      